
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customer-financial-statement-profit-loss",
  components: {
    Form,
    ElCurrencyInputNoSymbol,
  },

  data() {
    const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const statements = {
      profitNLost: {
        label: "Profit and Loss",
        child: {
          sales: {
            label: "Sales",
            value: [],
            total: 0,
          },
          costSales: {
            label: "(-) Cost of Sales",
            value: [],
            total: 0,
          },
          grossProfitLoss: {
            label: "Gross Profit / (Loss)",
            value: [],
            total: 0,
          },
          operatingExpenses: {
            label: "(-) Operating Expenses",
            value: [],
            total: 0,
          },
          otherIncome: {
            label: "(+) Other Income",
            value: [],
            total: 0,
          },
          depreciation: {
            label: "(-) Depreciation",
            value: [],
            total: 0,
          },
          profitLossInterestTax: {
            label: "Profit / (Loss) before Interest and Tax",
            value: [],
            total: 0,
          },
          interestExpense: {
            label: "(-) Interest Expense",
            value: [],
            total: 0,
          },
          profitLossTax: {
            label: "Profit / (Loss) before Tax",
            value: [],
            total: 0,
          },
          incomeTaxZakat: {
            label: "(-) Income Tax/ Zakat",
            value: [],
            total: 0,
          },
          netProfitLoss: {
            label: "Net Profit / (Loss) after Tax",
            value: [],
            total: 0,
          },
        },
      },
    };

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };

    return {
      router: useRouter(),
      isLoading,
      months,
      statements,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },

  async mounted() {
    setCurrentPageBreadcrumbs("Profit and Loss", [
      "Customer",
      "Financial Statements SME",
      "Profit and Losss",
    ]);
    this.init();
  },

  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });

        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENTS, this.cstmr_id)
          .then(() => {
            let sales = this.store.getters.getFinancialStatementsData.sales.raw
              .statements.totalSales;
            let costOfSales = this.store.getters.getFinancialStatementsData
              .costOfSales.raw.statements.totalCostSales;
            let otherIncome = this.store.getters.getFinancialStatementsData
              .otherIncomeAndExpenses.raw.statements.totalOtherIncome;
            let operatingExpenses = this.store.getters
              .getFinancialStatementsData.otherIncomeAndExpenses.raw.statements
              .totalOtherExpenses;
            let interestExpense = this.store.getters.getFinancialStatementsData
              .otherIncomeAndExpenses.raw.statements.totalInterestExpenses;
            let depreciation = this.store.getters.getFinancialStatementsData
              .taxAndDepreciation.raw.statements.totalDepreciation;
            let incomeTaxZakat = this.store.getters.getFinancialStatementsData
              .taxAndDepreciation.raw.statements.incomeTaxZakat;

            this.statements.profitNLost.child.sales.value = sales.value;
            this.statements.profitNLost.child.costSales.value =
              costOfSales.value;
            this.statements.profitNLost.child.operatingExpenses.value =
              operatingExpenses.value;
            this.statements.profitNLost.child.otherIncome.value =
              otherIncome.value;
            this.statements.profitNLost.child.interestExpense.value =
              interestExpense.value;
            this.statements.profitNLost.child.depreciation.value =
              depreciation.value;
            this.statements.profitNLost.child.incomeTaxZakat.value =
              incomeTaxZakat.value;

            this.calculate();
          });
      }

      // if (this.fin_id) {
      //   this.store
      //     .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
      //     .then(() => {
      //       this.statements = this.store.getters.getFinancialStatementData.raw.statements;
      //     });
      // }
      this.initMonths();
    },

    initMonths() {
      for (let key of Object.keys(this.statements.profitNLost.child)) {
        let profitNLostChild = this.statements.profitNLost.child;
        for (let index = 0; index < this.months.length; index++) {
          profitNLostChild[key].value.push(0);
        }
      }
    },
    calculate() {
      for (let i = 0; i < 12; i++) {
        this.statements.profitNLost.child.grossProfitLoss.value[i] =
          this.statements.profitNLost.child.sales.value[i] -
          this.statements.profitNLost.child.costSales.value[i];

        this.statements.profitNLost.child.profitLossInterestTax.value[i] =
          this.statements.profitNLost.child.grossProfitLoss.value[i] -
          this.statements.profitNLost.child.operatingExpenses.value[i] +
          this.statements.profitNLost.child.otherIncome.value[i] -
          this.statements.profitNLost.child.depreciation.value[i];

        this.statements.profitNLost.child.profitLossTax.value[i] =
          this.statements.profitNLost.child.profitLossInterestTax.value[i] -
          this.statements.profitNLost.child.interestExpense.value[i];

        this.statements.profitNLost.child.netProfitLoss.value[i] =
          this.statements.profitNLost.child.profitLossTax.value[i] -
          this.statements.profitNLost.child.incomeTaxZakat.value[i];
      }

      for (let keyChild of Object.keys(this.statements.profitNLost.child)) {
        this.statements.profitNLost.child[keyChild].total = 0;
        for (let i = 0; i < 12; i++) {
          this.statements.profitNLost.child[
            keyChild
          ].total += this.statements.profitNLost.child[keyChild].value[i];
        }
        this.statements.profitNLost.child[
          keyChild
        ].total = this.statements.profitNLost.child[keyChild].total.toFixed(2);
      }
    },

    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 10,
        raw: {
          statements: this.statements,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("updated");
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("saved");
          });
      }
    },

    swalMessage(m) {
      Swal.fire({
        title: "Congratulation!",
        text: "Profit and Loss Statement has been " + m + "!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        this.router.push({
          path: `/customers/details/${this.cstmr_id}/financial-statements/sme/list`,
        });
      });
    },
  },
});
